export const environment = {
  name: "Dev",
  production: false,
  baseURL: "https://sasbuf-api-dev.azure.chevron.com",
  UIbaseURL: "https://sasbuf-dev.azure.chevron.com",
  clientId: "980d9670-3875-4f10-a930-79b51e2e478e",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  lookbackDowloadPPTFileForEdit:"https://prod-113.westus.logic.azure.com:443/workflows/03215b8c5f9a45bf993ecd8cc9c68123/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=NRjzdmBPdX6AduvdUmDn1wAIjHGbCnL4dw__5PtljXk",
  lookbackCopyBackToBlobStorage:"https://prod-59.westus.logic.azure.com:443/workflows/fbb615a1518f4b968335ebb055a660c9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=KXqNhXDCo3BydjIkWZaK7_sr_8i0uRi8kHOB1aOME3M",
  endpointScopes: ['https://sasbuf-dev.azure.chevron.com/user_impersonation',
    'https://sasbuf-dev.azure.chevron.com/access_as_user']
};
