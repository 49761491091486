import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient) { }

  getAllEmployee(): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.http.get(`${environment.baseURL}/api/employee`, { headers });
  }

  getUserProfile(emailId: string): Observable<any> {
    return this.http.get(`${environment.baseURL}/api/Account/userprofile/${emailId}`);
  }

}
