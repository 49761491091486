import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatPaginatorModule } from '@angular/material/paginator'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NavbarComponent } from './main-app/shared/components/navbar/navbar.component';
import { FooterComponent } from './main-app/shared/components/footer/footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatListModule } from '@angular/material/list'
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';

/** */
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './main-app/shared/interceptor/auth.interceptor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CalAngularModule } from '@cvx/cal-angular';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatDialogModule,

    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    NgMultiSelectDropDownModule,

    CalAngularModule.forRoot({
      autoSignIn: true,
      popupForLogin: false,
      instance: "https://login.microsoftonline.com/",
      tenantId: environment.tenantId,
      clientId: environment.clientId,
      // redirectUri will need to match a redirectUri setup in your app registration
      redirectUri: environment.UIbaseURL,
      oidcScopes: ["openid", "profile", "User.Read", "offline_access"],
      graphScopes: ["User.Read", "profile"],
      cacheLocation: "sessionStorage"
    }),

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
