import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { Subscription } from 'rxjs';
import { eMenu } from './../../../models/enum';
import { IMenuVM } from './../../../models/master.model';
import { DataTransferService } from './../../../services/data-transfer.service';
import { EmployeeService } from './../../../services/employee.service';
import { LocalStorageService } from './../../../services/local-storage.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  [x: string]: any;

  profile!: string

  menus: IMenuVM[] = [];

  menuSubject: Subscription | null = null;
  selectedMenuId: number = 0;
  isOnlySingleEdit: boolean = false;
  displayName: string = "";
  currentUserProfile: ICvxClaimsPrincipal | null = null;
  loggedIn: boolean = false;

  constructor(private router: Router, private http: HttpClient,
    private authService: CalAngularService,
    private dataTransferService: DataTransferService, private employeeService: EmployeeService, private storageService: LocalStorageService, private calAngularService: CalAngularService) {

    this.InitializeSubscription();

  }

  ngOnInit(): void {

    this.authService.isUserSignedIn()
      .subscribe((value: any) => {
        this.loggedIn = value;
        if (this.loggedIn) {
          this.currentUserProfile = this.authService.cvxClaimsPrincipal;
          this.displayName = this.currentUserProfile.name;
          this.getUserProfile(this.currentUserProfile.email);
        }
        else {
          console.log("user not yet signed in");
        }
      })

  }

  InitializeSubscription() {

    this.menuSubject = this.dataTransferService.triggerMenu
      .subscribe((data: any) => {
        if (data && data.value) {

          this.menus = data.value.filter((e: any) => !e.parentMenuId);

          this.MenuDefault();
        }
      }, (_err: any) => {
        //print error
      });
  }

  ngOnDestroy(): void {
    this.menuSubject?.unsubscribe();
  }

  MenuDefault() {

    if (this.router.routerState.snapshot.url) {

      let _urlList = this.router.routerState.snapshot.url.split("/");

      if (_urlList && _urlList.length > 2) {
        let _urlSegment = _urlList[2];

        if (_urlSegment) {
          this.menus.filter(e => e.urlRoute == _urlSegment)
            .forEach(e => {
              this.selectedMenuId = e.menuId;
              this.isOnlySingleEdit = e.onlySingleEdit;

            });
        }

      }

    }

  }

  OnNavClick(menuId: number) {
    if ((menuId || menuId == 0) && this.menus) {
      let _selectedMenu: any = this.menus.find((e: any) => e.menuId == menuId);

      if (_selectedMenu) {
        this.selectedMenuId = menuId;
        if (menuId == 0) {
          this.router.navigate([`${_selectedMenu.urlRoute}`]);
        }
        else {
          this.router.navigate([`/lookback/${_selectedMenu.urlRoute}`]);
        }
      }

    }
  }
  /** 
    getProfile() {
      this.http.get(GRAPH_ENDPOINT)
        .subscribe((profile: ProfileType) => {
          this.profile = profile;
          if (this.profile) {
            this.getUserProfile(this.profile.userPrincipalName);
          }
         
  
        }, _error => {
          console.log("seeemmmmmmmmmmmmmmmmmmmm", _error);
        });
  
    }
  */

  getUserProfile(emailId?: string) {
    if (emailId) {
      this.employeeService.getUserProfile(emailId)
        .subscribe((res) => {
          this.storageService.clearUserProfile();
          if (res) {
            this.storageService.saveUserProfile(res);
          }
        }, _error => {
          //print error
        });
    }

  }
}
