import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public triggerLoaderChange: BehaviorSubject<any> = new BehaviorSubject(null);
  private _isLoading: boolean = false;
  private _counter: number = 0;

  constructor() {
    this._isLoading = false;
    this._counter = 0;
  }

  //get isLoading(): boolean {
  //  return this._isLoading;
  //}

  public show() {
    this._counter++;
    //console.log('Add pointer: ', this._counter);
    if (!this._isLoading) {
      this._isLoading = true;
      this.triggerLoaderChange.next({ value: true });
      //console.log('trigger: show ');
    }
  }

  public hide() {
    this._counter--;
    //console.log('Remove pointer: ', this._counter);
    if (this._counter <= 0) {
      this._counter = 0;
      if (this._isLoading) {
        this._isLoading = false;
        this.triggerLoaderChange.next({ value: false });
      }
      //console.log('trigger: hide ');
    }
  }

}
