
export class UrlConstant {

  public static readonly HOME: string = "home";
  public static readonly BROWSE: string = "browse";
  public static readonly CREATE: string = "create";
  public static readonly LOOKBACK: string = "lookback";

  public static readonly WELL: string = "well";
  public static readonly ASSET_DEVELOPMENT: string = "asset-development";

  public static readonly BROWSE_EDIT: string = "edit";
  public static readonly BROWSE_EDIT_ID: string = "edit/:id";
  //public static readonly ASSET_DEVELOPMENT: string = ":/under-construction";
  public static readonly PROJECT_TEAM: string = "project-team";
  public static readonly PROJECT_PURPOSE: string = "project-purpose";
  public static readonly PROJECT_INFO: string = "project-info";

  //Wells
  public static readonly GENERAL_INFORMATION: string = "general-information";
  public static readonly EXECUTIVE_SUMMARY: string = "executive-summary";
  public static readonly RUMS: string = "rums";
  public static readonly MOC: string = "moc";
  public static readonly PERFORMANCE: string = "performance";
  public static readonly NPT: string = "npt";
  public static readonly NCR: string = "ncr";

  //Asset Development
  public static readonly SSDHA: string = "ssdha";
  public static readonly PPFG_WBS: string = "ppfg-wbs";
  public static readonly FORMATION_TEST: string = "formation-test";
  public static readonly WELLS_FINAL_RESULTS: string = "wells-final-results";

  //Asset Development - WELLS-FINAL-RESULTS
  public static readonly LOG_CORRELATION: string = "log-correlation";
  public static readonly SEISMIC_SECTION: string = "seismic-section";
  public static readonly WELL_TOPS: string = "well-tops";
  public static readonly RESERVOIR_PROPERTIES: string = "reservoir-properties";
  public static readonly PRESSURE_ACQUISITION: string = "pressure-acquisition";
  public static readonly PERFORMANCE_BY_WELL: string = "performance-by-well";
  public static readonly PRODUCERS_PERFORMANCE: string = "producers-performance";
  public static readonly INJECTORS_PERFORMANCE: string = "injectors-performance";

  public static OfficeAppViewUrl(encodedUrl: string): string {
    return `https://view.officeapps.live.com/op/view.aspx?src=${encodedUrl}&wdOrigin=BROWSELINK`;
  }

  //Error
  public static readonly UNDER_CONSTRUCTION: string = "under-construction";


}


export class RouteUrlConstant {
  public static readonly BROWSE_EDIT: string = "lookback/browse/edit";
}

export class Common {
  public static readonly CONTAINER_MEDIA: string = "media";
  public static readonly CONTAINER_FILEUPLOAD: string = "fileupload";
}
