import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
    // This is intentional
  }

  saveUserProfile(userProfile: any) {
    if (userProfile) {
      this.removeUserId();

      this.saveUserId(userProfile.empUid);

    }
  }

  clearUserProfile() {
    this.removeUserId();
  }

  public get UserId(): string {
    let _userId = window.localStorage.getItem("UserId");
    return _userId ? _userId : "0";
  }

  private saveUserId(empId: string) {
    window.localStorage.setItem("UserId", empId);
  }

  private removeUserId() {
    window.localStorage.removeItem("UserId");
  }
}
